import React, { useEffect } from 'react';
import { navigate } from 'gatsby'
import { usePrismicPreview } from 'gatsby-source-prismic';
import { resolvePrismicLink } from '../utils';

const PreviewPage = () => {
  const preview = usePrismicPreview({
    // The repositoryName value from your `gatsby-config.js`.
    repositoryName: 'ipone',
    linkResolver: () => resolvePrismicLink,
  });

  const { isPreview, previewData, isLoading, path } = preview;

  useEffect(() => {
    // If this is not a preview, skip.
    //   null = Not yet determined if previewing.
    //   true = Preview is available.
    //   false = Preview is not available.
    if (!isPreview || isLoading) return;

    // Save the preview data to somewhere globally accessible. This could be
    // something like a global Redux store or React context.
    //
    // We'll just put it on window.
    window.__PRISMIC_PREVIEW_DATA__ = previewData;

    return navigate(path);
  }, [isPreview, isLoading, previewData, path]);

  // Tell the user if this is not a preview.
  if (isPreview === false) return <div>Not a preview!</div>;

  return (
    <div
      style={{
        margin: 50,
        width: '100%',
        textAlign: 'center'
      }}
    >
      <strong>Loading preview...</strong>
    </div>
  );
}

export default PreviewPage;